import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { IndexItSolutionComponent } from './core/components/index-it-solution/index-it-solution.component';
import { MasterPageComponent } from './core/components/master-page/master-page.component';
import { LegalNotesComponent } from './core/components/legal-notes/legal-notes.component';

const routes: Routes = [
  {
    path: '',
    component: MasterPageComponent,
    children: [
      { path: '', component: IndexItSolutionComponent }
    ]
  },
  { path: 'legal-notes', pathMatch: 'full', component: LegalNotesComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 64],
      onSameUrlNavigation: 'reload',
      useHash: true
    })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
