import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';

import { ScrollspyDirective } from './scrollspy.directive';
import { FeatherModule } from 'angular-feather';
import { MemberComponent } from './member/member.component';
import { FeaturesComponent } from './features/features.component';

@NgModule({
  declarations: [
    ScrollspyDirective,
    MemberComponent,
    FeaturesComponent
  ],
  imports: [
    CommonModule,
    FeatherModule,
    RouterModule
  ],
  exports: [
    ScrollspyDirective,
    MemberComponent,
    FeaturesComponent
  ]
})

export class SharedModule { }
