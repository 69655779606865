<div class="row">
    <div class="col-lg-3 col-md-6 mt-4 pt-2">
      <div class="card team text-center border-0 bg-light">
        <div class="position-relative">
          <img src="assets/images/client/01.jpg" class="img-fluid avatar avatar-ex-large rounded-circle shadow"
            alt="">
          <!--end icon-->
        </div>
        <div class="card-body py-3 px-0 content">
          <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Maxim Girnyak</a></h5>
          <small class="designation text-muted">Senior Software Developer, Founder</small>
        </div>
      </div>
    </div>
    <div class="col-lg-9 col-md-6 mt-4 pt-2">
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dolor nisi,
        aliquam in nulla nec, commodo egestas lorem. Mauris feugiat, urna vel gravida
        elementum, nisl lorem sagittis elit, in laoreet nisi augue convallis mauris.
        Suspendisse pharetra maximus venenatis. Sed euismod nunc ut enim rhoncus,
        sit amet viverra sem gravida. Nullam volutpat ut urna vitae malesuada.
        Vivamus mattis turpis eu tempor aliquam. Etiam sed tincidunt libero. Nullam eu semper diam, nec congue ante.
      </p>
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dolor nisi,
        aliquam in nulla nec, commodo egestas lorem. Mauris feugiat, urna vel gravida
        elementum, nisl lorem sagittis elit, in laoreet nisi augue convallis mauris.
        Suspendisse pharetra maximus venenatis.
      </p>
    </div>
</div>
<div class="row">
  <div class="col-lg-3 col-md-6 mt-4 pt-2">
    <div class="card team text-center border-0 bg-light">
      <div class="position-relative">
        <img src="assets/images/client/04.jpg" class="img-fluid avatar avatar-ex-large rounded-circle shadow"
             alt="">
        <!--end icon-->
      </div>
      <div class="card-body py-3 px-0 content">
        <h5 class="mb-0"><a href="javascript:void(0)" class="name text-dark">Andriy Dmytruk</a></h5>
        <small class="designation text-muted">Senior Software Developer, Founder</small>
      </div>
    </div>
  </div>
  <div class="col-lg-9 col-md-6 mt-4 pt-2">
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dolor nisi,
      aliquam in nulla nec, commodo egestas lorem. Mauris feugiat, urna vel gravida
      elementum, nisl lorem sagittis elit, in laoreet nisi augue convallis mauris.
      Suspendisse pharetra maximus venenatis. Sed euismod nunc ut enim rhoncus,
      sit amet viverra sem gravida. Nullam volutpat ut urna vitae malesuada.
      Vivamus mattis turpis eu tempor aliquam. Etiam sed tincidunt libero. Nullam eu semper diam, nec congue ante.
    </p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dolor nisi,
      aliquam in nulla nec, commodo egestas lorem. Mauris feugiat, urna vel gravida
      elementum, nisl lorem sagittis elit, in laoreet nisi augue convallis mauris.
      Suspendisse pharetra maximus venenatis.
    </p>
  </div>
</div>
