import { Component, OnInit } from '@angular/core';

interface feature {
  icon: string;
  title: string;
};

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.css']
})
export class FeaturesComponent implements OnInit {

  /**
   * Clients Logo
   */
   featuresdata: feature[] = [
    {
      icon: "server-connection",
      title: "Java EE, Jakarta EE, Spring"
    },
    {
      icon: "brackets-curly",
      title: "PHP, Symfony"
    },
   {
       icon: "java-script",
       title: "NodeJs, ExpressJs"
   },
    {
      icon: "arrow",
      title: "html 5, css, javascript"
    },
    {
      icon: "apps",
      title: "Angular, React"
    },
    {
      icon: "database",
      title: "Oracle, MySQL, MongoDb"
    },
   {
       icon: "mobile-android",
       title: "Nativescript, Ionic,  Flutter"
   },
   {
       icon: "grids",
       title: "Agile, Scrum"
   }
  ];

  constructor() { }

  ngOnInit(): void {
  }

}
