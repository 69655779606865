<!-- Navbar STart -->
<header id="topnav" class="defaultscroll sticky" (window:scroll)="windowScroll()" [class]="navClass">
  <div class="container">
    <!-- Logo container-->
    <div>
      <a class="logo" routerLink="/">
        <div class="logo-img">
          <img src="assets/images/logo-dark.svg" class="l-dark logo-light-mode" height="24" alt="">
          <img src="assets/images/logo-light.svg" class="logo-dark-mode" height="24" alt="">
        </div>
        <div class="logo-text">
          <span class="sysburg">SYSBURG</span>
          <span class="se">SOFTWARE ENGINEERING</span>
        </div>
      </a>
    </div>

    <span style="display: none">
      <div class="buy-button">
        <a>English</a> / <a>Deutsch</a>
      </div>
    </span>
    <!--end login button-->

    <ng-container *ngIf="!hideMenu">

      <div class="menu-extras">
        <div class="menu-item">
          <!-- Mobile menu toggle-->
          <a class="navbar-toggle" id="isToggle" (click)="toggleMenu()" [ngClass]="{'open': isCondensed === true}">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
          <!-- End mobile menu toggle-->
        </div>
      </div>

      <div id="navigation">
        <!-- Navigation Menu-->
        <ul class="navigation-menu" [class]="navClass">
          <li><a href="javascript: void(0);" class="nav-link-ref" [ngxScrollTo]="'#home'">Home</a></li>
          <li><a href="javascript: void(0);" class="nav-link-ref" [ngxScrollTo]="'#services'" [ngxScrollToOffset]="-60">Services</a></li>
          <li><a href="javascript: void(0);" class="nav-link-ref" [ngxScrollTo]="'#contact'" [ngxScrollToOffset]="-60">Contact</a></li>
        </ul>
        <!--end navigation menu-->
      </div>
      <!--end navigation-->

    </ng-container>
  </div>
  <!--end container-->
</header>
<!--end header-->
<!-- Navbar End -->
