<div class="row">
  <div class="col-lg-4 col-md-6 mt-4 pt-2" *ngFor="let data of featuresdata">
    <div class="d-flex key-feature align-items-center p-3 rounded shadow">
      <div class="icon text-center rounded-circle me-3">
        <i class="uil uil-{{data.icon}}"></i>
      </div>
      <div class="flex-1">
        <h4 class="title mb-0">{{data.title}}</h4>
      </div>
    </div>
  </div>
  <!--end col-->
</div>
