<!-- Hero Start -->
<section class="bg-half-170 pb-0 bg-primary d-table w-100" id="home"
  style="background: url('assets/images/bg2.png') center center;">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-lg-7 col-md-6">
        <div class="title-heading">
          <h4 class="heading text-white mb-3 title-dark">IT Solution Company </h4>
          <p class="para-desc text-white-50">We offer custom software development and maintenance,
            but are also happy to support your development teams.
            We work methodically, agilely and reliably.</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<section id="services" class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Our services</h4>
          <p class="text-muted para-desc mb-0 mx-auto">Let’s work together!</p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->

    <div class="row">
      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-rocket"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Custom software development</h5>
            <p class="para text-muted mb-0">We design, develop and launch your project.
              From small applications to large scale enterprise systems</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-chart-line"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-window"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Legacy systems modernization</h5>
            <p class="para text-muted mb-0">Make your business software up-to-date with
              the latest technologies and standards. Reduce your daily costs</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-crosshairs"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-mobile-vibrate"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Cross platform mobile App development</h5>
            <p class="para text-muted mb-0">Full-cycle development from idea to product launching,
              support and continuous delivery</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-airplay"></i>
          </span>
        </div>
      </div>
      <!--end col-->

      <div class="col-lg-3 col-md-4 mt-4 pt-2">
        <div
          class="card features fea-primary rounded p-4 bg-light text-center position-relative overflow-hidden border-0">
          <span class="h1 icon2 text-primary">
            <i class="uil uil-users-alt"></i>
          </span>
          <div class="card-body p-0 content">
            <h5>Dedicated developers</h5>
            <p class="para text-muted mb-0">Increase your speed of delivery, and allow you to build the most effective
              team faster working alongside with our people.</p>
          </div>
          <span class="big-icon text-center">
            <i class="uil uil-rocket"></i>
          </span>
        </div>
      </div>
      <!--end col-->

    </div>
    <!--end row-->


  </div>
  <!--end container-->
</section>
<!--end section-->

<section id="about" class="section bg-light">
  <div class="container mt-60">
    <div class="row justify-content-center">
      <div class="col-12 text-center">
        <div class="section-title mb-4 pb-2">
          <h4 class="title mb-4">Technology stack</h4>
          <p class="text-muted para-desc mx-auto mb-0"></p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
    <app-features></app-features>
  </div>
  <!--end container-->
</section>
<!--end section-->

<section id="contact" class="section">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-12">
        <div class="section-title text-center mb-4 pb-2">
          <h4 class="title mb-4">Contact</h4>
          <p class="text-muted para-desc mx-auto mb-0"></p>
        </div>
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->

  <div class="container">
    <div class="row align-items-center">
      <div class="col-12">
        <div class="card custom-form rounded border-0 shadow p-4">
          <form method="post" name="myForm" [formGroup]="contactForm" (submit)="onSubmit()" [ngClass]="{'submitted': submitted}">
            <p id="error-msg" class="mb-0"></p>
            <div id="simple-msg"></div>
            <div class="row">
              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Your Name <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="user" class="fea icon-sm icons"></i-feather>
                    <input name="name" formControlName="name" id="name" type="text" class="form-control ps-5" placeholder="Name :">
                  </div>
                  <span class="error" *ngIf="(contactFormControl.name.invalid  && (submitted || contactFormControl.name.dirty || contactFormControl.name.touched))">
                    <div *ngIf="contactFormControl.name.errors?.['required']">Name is required.</div>
                  </span>
                </div>
              </div>

              <div class="col-md-6">
                <div class="mb-3">
                  <label class="form-label">Your Email <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="mail" class="fea icon-sm icons"></i-feather>
                    <input name="email" formControlName="email"  id="email" type="email" class="form-control ps-5" placeholder="Email :">
                  </div>
                  <span class="error" *ngIf="(contactFormControl.email.invalid  && (submitted || contactFormControl.email.dirty || contactFormControl.email.touched))">
                    <div *ngIf="contactFormControl.email.errors?.['required']">Email is required.</div>
                    <div *ngIf="contactFormControl.email.errors?.['email']">Email is not valid.</div>
                  </span>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Organization</label>
                  <div class="form-icon position-relative">
                    <i-feather name="users" class="fea icon-sm icons"></i-feather>
                    <input name="organization" formControlName="organization" id="organization" class="form-control ps-5" placeholder="Organization :">
                  </div>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Subject <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="book" class="fea icon-sm icons"></i-feather>
                    <input name="subject" formControlName="subject" id="subject" class="form-control ps-5" placeholder="Subject :">
                  </div>
                  <span class="error" *ngIf="(contactFormControl.subject.invalid  && (submitted || contactFormControl.subject.dirty || contactFormControl.subject.touched))">
                    <div *ngIf="contactFormControl.subject.errors?.['required']">Subject is required.</div>
                  </span>
                </div>
              </div>
              <!--end col-->

              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">Message <span class="text-danger">*</span></label>
                  <div class="form-icon position-relative">
                    <i-feather name="message-circle" class="fea icon-sm icons clearfix"></i-feather>
                    <textarea name="comments" formControlName="message" id="comments" rows="4" class="form-control ps-5"
                              placeholder="Message :"></textarea>
                  </div>
                  <span class="error" *ngIf="(contactFormControl.message.invalid  && (submitted || contactFormControl.message.dirty || contactFormControl.message.touched))">
                    <div *ngIf="contactFormControl.message.errors?.['required']">Message is required.</div>
                  </span>
                </div>
              </div>
            </div>
            <ngb-alert #contactFormAlert *ngIf="submitMessage" [type]="submitMessageType" (closed)="submitMessage = ''">{{submitMessage}}</ngb-alert>
            <div class="row align-items-center">
              <div class="col-12">
                <div class="d-grid">
                  <button type="submit" id="submit" name="send" class="btn btn-primary">Send Message</button>
                </div>
              </div>
              <!--end col-->
            </div>
            <!--end row-->
          </form>
        </div>
        <!--end custom-form-->
      </div>
      <!--end col-->
    </div>
    <!--end row-->
  </div>
  <!--end container-->
</section>
<!--end section-->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
  <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
