import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbAlert } from '@ng-bootstrap/ng-bootstrap';
import { EmailService } from '../../../service/email.service';
import { Meta } from '@angular/platform-browser';

@Component({
  selector: 'app-index-it-solution',
  templateUrl: './index-it-solution.component.html',
  styleUrls: ['./index-it-solution.component.css']
})

/***
 * It-Solution Component
 */
export class IndexItSolutionComponent implements OnInit {

  /***
   * Nav Bg Class Add
   */
  navClass = 'nav-light';

  @ViewChild('contactFormAlert', {static: false}) contactFormAlert: NgbAlert;

  contactForm: FormGroup = this.buildContactForm();
  submitMessage = '';
  submitMessageType = '';
  submitted = false;

  constructor(private fb: FormBuilder,
              private emailService: EmailService,
              private meta: Meta) {
    this.meta.removeTag('name = robots');
  }

  ngOnInit(): void {
  }

  get contactFormControl(): any {
    return this.contactForm.controls;
  }

  onSubmit(): void {

    this.submitted = true;

    if (this.contactForm.valid) {
      this.emailService.sendContactFormEmail(this.contactForm.value)
          .subscribe({next: () => this.showSuccess(), error: () => this.showError()});
    }
  }

  showSuccess(): void {
    this.submitted = false;
    this.contactForm.reset();
    this.submitMessage = 'Your message was sent';
    this.submitMessageType = 'success';
    this.closeAlert();
  }

  showError(): void {
    this.submitMessage = 'There was a problem sending your message. Please try again later';
    this.submitMessageType = 'danger';
    this.closeAlert();
  }

  closeAlert(): void {
    setTimeout(() => {
      if (this.contactFormAlert) {
        this.contactFormAlert.close();
      }
    }, 5000);
  }

  buildContactForm(): FormGroup {

    return this.fb.group({
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      organization: [''],
      subject: ['', Validators.required],
      message: ['', Validators.required]
    });
  }
}
