<app-header [hideMenu]="true"></app-header>
<!-- Hero Start -->
<section class="bg-half bg-light d-table w-100" id="home">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-12 text-center">
                <div class="page-next-level">
                    <h4 class="title"> Legal notice</h4>
                    <div class="page-next">
                        <nav aria-label="breadcrumb" class="d-inline-block">
                            <ul class="breadcrumb bg-white rounded shadow mb-0">
                                <li class="breadcrumb-item"><a routerLink="/">Sysburg</a></li>
                                <li class="breadcrumb-item active" aria-current="page">Legal notes</li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- Hero End -->

<!-- Shape Start -->
<div class="position-relative">
    <div class="shape overflow-hidden text-white">
        <svg viewBox="0 0 2880 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 48H1437.5H2880V0H2160C1442.5 52 720 0 720 0H0V48Z" fill="currentColor"></path>
        </svg>
    </div>
</div>
<!--Shape End-->

<!-- Start Terms & Conditions -->
<section class="section">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-lg-9">
                <div class="card shadow border-0 rounded">
                    <div class="card-body">
                        <h5 class="card-title">1. Ownership of the portal</h5>
                        <p class="text-muted">Sysburg Software Engineering SL Registered in the Mercantile Registry of Alicante,
                            T 3630, F 163, S 8, H A 131546, I/A 2 (13.06.22), 1st registration registered office:
                            calle Joan Baptista Basset i Ramos 20 esc 1 4C, 03560 El Campello (Alicante).
                            Tax identification code: B-10694891 (hereinafter The Company) is the owner of this website (hereinafter, Portal).
                            Electronic contact with The Company can be done through the e-mail address contact@sysburg.com.</p>

                        <h5 class="card-title">2. Object</h5>
                        <p class="text-muted">
                            This Legal Notice sets out the conditions of use that regulate the access, navigation and use of
                            the Portal, as well as the responsibilities derived from the use, contracting or rendering of the
                            products or services that, where appropriate, may be offered, as well as the contents that
                            make it up, and without prejudice to The Company being able to establish specific conditions
                            that regulate the use, contracting or rendering of specific products or services that,
                            in their case, are offered to Users through the Portal. In any case, these conditions
                            form an integral part of this Legal notice.
                        </p>
                        <p class="text-muted">The mere access to the Portal, the completion of forms, the sending of
                            requests for information, subscription to information, queries, complaints, contracting
                            offers, curriculum vitae's and, in general, any act of a similar nature to the previous
                            ones carried out through the Electronic forms or mailboxes existing on the Portal will imply,
                            for their part, the unreserved acceptance of each and every one of the regulations that form
                            part of this Legal Notice and the acquisition of the consideration of User of the Portal.
                            Consequently, You must carefully read and understand the contents of this Legal Notice.
                        </p>
                        <p class="text-muted">
                            In case of being offered, through the Portal, the use, contracting or rendering of products or
                            services, any use or request by the User will also imply unreserved acceptance of the
                            particular conditions that, if applicable, have been established for this purpose and,
                            as indicated above, will form an integral part of this Legal Notice.
                        </p>
                        <h5 class="card-title">3. Access and use of the portal</h5>
                        <p class="text-muted">
                            The access to the website by Users is free and gratuitous. However, the use, contracting or
                            provision of the products or services that, where appropriate, may be offered may be
                            subject to the prior observance of formal requirements such as the completion of the
                            corresponding form, payment of expenses or fees or the prior acceptance of the particular
                            conditions that result from their application.
                        </p>
                        <p class="text-muted">
                            The mere access to the Portal does not imply, in itself, the existence of any type of link
                            or commercial relationship between The Company and the User, except when this has been
                            notified, by the appropriate means for which they have been determined and the User
                            has complied with the previous requirements that, in his case, have been established.
                        </p>
                        <p class="text-muted">
                            The inclusion in the Portal of information regarding products or services offered by
                            The Company has exclusively advertising, and informational purposes unless another purpose is expressly established.
                        </p>
                        <p class="text-muted">
                            In any case, the access, navigation and use of the Portal and, where appropriate, the use and contracting of services or products that are offered through it are done under the sole and exclusive responsibility of the User, the user guarantees to diligently and faithfully observe any additional instruction, given by The Company or by authorized personnel of The Company, regarding the use of the Portal and its contents. In any case the User agrees to use the contents, products and services diligently, correctly and lawfully, in accordance with current legislation and in particular, it guarantees to refrain from:
                        </p>
                        <ul class="text-muted">
                            <li>Using them for purposes or effects contrary to the law, morality and good customs that are generally accepted in relation to public order and to instructions received from The Company.</li>
                            <li>Use them with the purpose of harming the legitimate rights of third parties.</li>
                            <li>Use the contents and products and, in particular, information of any kind obtained through the website or services to send advertising, communications with the purpose of direct sales or with any other kind of commercial purpose, unsolicited messages addressed to a number people regardless of their purpose and refrain from selling or otherwise disclose such information.</li>
                        </ul>
                        <h5 class="card-title">4. Responsibilities and limitations</h5>
                        <p class="text-muted">
                            The Company cannot guarantee the reliability, usefulness or accuracy of the information provided through the Portal. In consequence, The Company does not guarantee nor is liable or responsible for:
                        </p>
                        <ul class="text-muted">
                            <li>The continuity of the contents of the Portal or lack of availability or accessibility of the Portal or its technical continuity;</li>
                            <li>The absence of errors in the content or products;</li>
                            <li>The absence of viruses and other harmful components on the Website or the server that supplies it;</li>
                            <li>The invulnerability of the website or the impregnability of the security measures to be taken;</li>
                            <li>Where appropriate, the lack of utility or performance of the content or services of the Portal;</li>
                            <li>Damages that it may cause, to itself or to a third party, or to any person that infringes the conditions, norms and instructions that The Company established on the website or through the violation of its security systems;</li>
                            <li>Any other damage that may be caused by reasons inherent to the non-operation or faulty operation of the Portal or the web sites to which, in his case, have been able to establish links.</li>
                        </ul>
                        <p class="text-muted">
                        However, The Company declares that it has taken all necessary measures, within its capabilities and state of technology, to ensure the functioning of the Website and avoid the existence and transmission of viruses and other harmful components to Users.
                        </p>
                        <p class="text-muted">
                        The Company makes every effort to avoid errors in the contents that are published on the Portal. All contents offered through the Portal are, within the possibilities of The Company, to date, The Company reserves the right to modify them at any time. The Company is not responsible for any consequences that may arise from errors in the contents that may appear on the Portal that may be provided by third parties.
                        </p>
                        <p class="text-muted">
                        Any communication or transmission of contents which infringes the rights of third parties and whose content is threatening, obscene, defamatory, pornographic, xenophobic, discriminatory, that attempts against the dignity of the individual or the rights of children, current legislation or any conduct which incites or constitutes a criminal offense is prohibited.
                        </p>
                        <p class="text-muted">
                        It also prohibits the inclusion and communication of content by users which may be false or inaccurate and which induce or may induce to error to the rest of the users or staff of The Company, in particular content which is prohibited or protected by any intellectual or industrial property belonging to third persons who do not have the authorization of the holder, or discredit the reputation or credibility of The Company considered as a case of illegal, deceptive or unfair advertising or incorporate viruses or any other electronic element that can damage or impede the operation of the website, network, computer equipment of or third parties or portal access to other users.
                        <p class="text-muted">
                        The Company may prohibit the access to the website to any User who undertakes any of the previously mentioned actions, by way of example and not limitation, in this paragraph.
                        </p>

                        <h5 class="card-title">5. Rights of intellectual and industrial property</h5>
                        <p class="text-muted">
                        The Company owns or has obtained the appropriate license of exploitation rights in the field of intellectual and industrial property and image on the content available through it, including but not limited and non-exhaustive, texts, graphic designs, drawings, codes, software, photographs, videos, sounds, bases data, indexes, images, brands, logos, expressions and information and, in general, any other creation protected by national and international rules and treaties intellectual and industrial property (in later, collectively, The Content).
                        </p>
                        <p class="text-muted">
                        All rights of intellectual and industrial property of The Content are reserved and, in particular, it is forbidden to modify, copy, reproduce, publicly communicate, transform or distribute in any way the whole or part of The Content included on the website for public or commercial purposes, without the prior express, written permission of The Company or, the owner of the corresponding rights.
                        </p>
                        <p class="text-muted">
                        The access and navigation of the User through the Portal is in no case to be construed as a waiver, transmission, license total or partial cession of the rights listed above by The Company. Consequently, it is not permitted to suppress, elude or manipulate the copyright notice ("copyright") and any other data identifying the rights of The Company or of its owners incorporated to The Content, as well as the technical protection devices, digital fingerprints or any mechanisms of information and / or identification that might be contained in the same. References to names and trademarks or registered trademarks, logos or other distinctive signs, whether owned by The Company or by third parties, implicitly prohibit their use without the consent of The Company or its legitimate owners. At no time, unless expressly stated access or use of the Portal or its contents, gives the user any right over the brands, logos or distinctive signs included in it are protected by law.
                        </p>

                        <h5 class="card-title">6. Privacy policy and data protection</h5>
                        <p class="text-muted">
                        In accordance with the established rules of Protection of Personal Data, we inform you that, when it is necessary for the User to register or provide personal information, you are providing your personal data to the Responsible of the Treatment: Sysburg Software Engineering SL (The Company).
                        </p>

                        <strong>6.1. Registration of treatment of activities</strong>
                        <p class="text-muted">
                            Sysburg Software Engineering will maintain all records of activities of data treatment required by Article 30(2) of REGULATION (EU) 2016/679 OF the EUROPEAN PARLIAMENT AND COUNCIL of April 27, 2016 (general Regulation on data protection), Hereafter RGPD).
                        </p>

                        <strong>6.2. Purpose of the processing</strong>
                        <p class="text-muted">
                        The data collected will be only that which you have detailed on the form on the website or in your email. The Company will treat your personal data according to the following purposes:
                        </p>
                        <ul class="text-muted">
                            <li>Manage your navigation through the web site.</li>
                            <li>Manage the Customer service and, in general, the relations with the Client, to respond to the doubts, complaints, comments, or concerns you may have relating to the delivery of the contracted services, to the information contained on the website, as well as any other queries you may have.</li>
                            <li>Manage communication and commercial offer to Clients of new services or products or communication and the realization of direct marketing actions.</li>
                            <li>Manage the acquisition of products or services of The Company by new customers.</li>
                            <li>Manage the delivery of the newsletter or subscribe to channels of information.</li>
                            <li>Manage offers of employment or receipt of curriculum vitae by the interested parties.</li>
                        </ul>

                        <strong>6.3. Legitimation of the treatment of your personal data</strong>
                        <p class="text-muted">
                        The legal basis for the processing of data for purpose 2 is the provision of the relevant service, such as the treatment needed for the execution of a contract or pre-contract.
                        </p>
                        <p class="text-muted">
                        The legal basis for the processing of data for purpose 3 is the legitimate business interest to be able to retain our Clients, offer them commercial information and the possibility of contracting new services or products and to carry out direct marketing actions.
                        </p>
                        <p class="text-muted">
                        In other cases the legitimacy is based on the consent granted to us when the requested information is sent to us by email or by filling out a web form for the requested information. These acts represent an authorization of use for the established purpose.
                        </p>
                        <p class="text-muted">
                        In all cases, the interested parties may object to the processing of their data and exercise their rights as set out in paragraph 6.4 below.
                        </p>

                        <strong>6.4. Rights</strong>
                        <p class="text-muted">
                        The User has recognized the following rights:
                        </p>
                        <ul class="text-muted">
                            <li>The right to request access to your personal data.</li>
                            <li>The right to request the rectification or erasure (the right to be forgotten).</li>
                            <li>Right to request the limitation, and to oppose the treatment.</li>
                            <li>The right to data portability</li>
                        </ul>
                        <p class="text-muted">
                        To exercise your rights you only have to make a request in writing and accompany it with a copy of your ID to
                            Sysburg Software Engineering SL, calle Joan Baptista Basset i Ramos 20 esc 1 4C, 03560 El Campello (Alicante) or contact@sysburg.com.
                        </p>

                        <strong>6.5. Sysburg Software Engineering's Data Protection Delegate</strong>
                        <p class="text-muted">
                            Sysburg Software Engineering has appointed a Data Protection Delegate with the functions indicated under the RGPD.
                            For the cases in which intervention is required, the CLIENT will be able to get in contact
                            at the registered postal address of Sysburg Software Engineering and the email address contact@sysburg.com.
                        </p>

                        <strong>6.6. Security measures</strong>
                        <p class="text-muted">
                        The Company has implemented the necessary technical and organizational measures to ensure the security of their personal data and avoid its alteration, loss, treatment or unauthorized access, taking into account the state of the technology, the nature of the stored data and the risks arising from human action or from the physical and natural environment to which they are exposed.
                        </p>
                        <p class="text-muted">
                        Such treatment of the data is carried out in accordance with the above-mentioned principles and, in particular, subject to the duty of confidentiality and secrecy.
                        </p>

                        <h5 class="card-title">7. Duration and modification</h5>
                        <p class="text-muted">
                        The Company reserves the right to modify this Legal Notice and the particular conditions that, in its case, have been established for the use or contracting of the products or services provided on or through the site, when deemed appropriate or with the aim of adapting to technological and legislative changes and amendments shall be valid and shall take effect as from the publication on the Portal.
                        </p>
                        <p class="text-muted">
                        The entry into force of these terms of use matches, therefore its exposure time, until modified in whole or in part, at which time the modified terms of use will become valid.
                        </p>
                        <p class="text-muted">
                        The Company may terminate, suspend or interrupt, at any time without prior notice, access to the contents of the page, without possibility for the User to demand compensation. After the extinction, the prohibitions of use of the content, set out above in this privacy policy will remain in force.
                        </p>

                        <h5 class="card-title">8. Communications</h5>
                        <p class="text-muted">
                        Any communication between The Company and the user, should be addressed to The Company to the
                            email postal or address before indicated. In any case, communications from The Company to
                            the User will be carried out in accordance with the contact details provided or provided.
                            Specifically, the user accepts the use of e-mail as a valid procedure for the exchange of
                            information and the remission of communications between or with The Company.
                        </p>
                        <p class="text-muted">
                        For the commercial communications, in compliance with the provisions of Law nº 34/2002, of 11 July,
                            Services of Information Society and Electronic Commerce, and the applicable rules on the
                            protection of personal data, the consent of the User will be duly obtained at the time of collecting the corresponding data.
                        </p>
                        <p class="text-muted">
                        The User may revoke at any time the consent given to the receipt of commercial communications
                            with the simple notification of their will by sending an email through the email address
                            contact@sysburg.com, notwithstanding the act of cancellation to be carried out as appropriate
                            and in application of the rules on protection of personal data.
                        </p>

                        <h5 class="card-title">9. General information</h5>
                        <p class="text-muted">
                        The headings of the different clauses are only informative, and will not affect, qualify or modify the interpretation of the Legal notice.
                        </p>
                        <p class="text-muted">
                        In case there is a discrepancy between the provisions of this Legal Notice and the particular conditions that may be established in relation to the products or services offered on the Portal, the provision of the particular conditions will prevail.
                        </p>
                        <p class="text-muted">
                        In the event that any provision or provisions of this Legal Notice are considered null and void or inapplicable, in whole or in part, by any Court, Court or competent administrative body, said nullity or non-application will not affect the other provisions of the Legal Notice or the particular conditions that, where appropriate, may have been established.
                        </p>
                        <p class="text-muted">
                        The lack of exercise or execution by The Company of any right or provision contained in this Legal Notice shall not constitute a waiver, unless acknowledgment and agreement in writing is provided.
                        </p>

                        <h5 class="card-title">10. Applicable legislation and competent courts</h5>
                        <p class="text-muted">
                        The present Legal Notice and the relations established between The Company and the User, in particular the knowledge and resolution of any disputes, discrepancies or differences that might arise, will be governed and settled in accordance with the provisions of Spanish law concerning applicable law and competent jurisdiction.
                        </p>
                        <p class="text-muted">
                        However, for cases in which the applicable rules provide for the possibility of the parties submitting to a jurisdiction, The Company and the User, expressly waiving any other jurisdiction that may apply, shall submit any disputes or litigation to the knowledge of the parties Courts and Tribunals of the city where The Company is domiciled as provided in this Legal Notice.
                        </p>
                    </div>
                </div>
            </div>
            <!--end col-->
        </div>
        <!--end row-->
    </div>
    <!--end container-->
</section>
<!--end section-->
<!-- End Terms & Conditions -->

<!-- Back to top -->
<a href="javascript: void(0);" class="btn btn-icon btn-primary back-to-top" id="back-to-top" [ngxScrollTo]="'#home'">
    <i-feather name="arrow-up" class="icons"></i-feather>
</a>
<!-- Back to top -->
<app-footer [hideFooter]="false" [hideMenu]="true"></app-footer>
